.tox .tox-tbtn--bespoke {
    background: none!important;
}

div.tox {
    &.invalid {
        border: var(--border-width) solid var(--input-invalid-border);
    }
}

div.tox.tox-tinymce-aux {
    z-index: 3000;
}


.tox {

    :not(.tox-tinymce-inline) .tox-editor-header {
        background: var(--tinymce-header-background) !important;
    }

    .tox-edit-area__iframe {
        background: var(--tinymce-body-background) !important;
    }

    .tox-editor-container {
        .tox-editor-header {
    
            .tox-toolbar-overlord {
                .tox-toolbar__primary {
                    background: var(--tinymce-header-background);
                }
            }
        }
    }

    .tox-tbtn {

        &--select {
            color: var(--tinymce-header-text) !important;
        }

        &--bespoke {
            color: var(--tinymce-header-text) !important;
        }
    

        svg {
            fill: var(--tinymce-header-text) !important;
            color: var(--tinymce-header-text) !important;
        }
    }
}
