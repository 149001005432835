div.page.page--with-navigation {
    grid-template-rows: auto max-content max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-template-areas:
        "banner banner"
        "header header"
        "navigation viewheader"
        "navigation content";

    & > div.view__header {
        grid-area: viewheader;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        display: grid;
        padding: var(--spacing-xl) var(--spacing-xl) 0;
		background: var(--view-header-background);

        border-bottom: 0.1rem solid var(--view-header-border);

        & > h2 {
            margin: var(--spacing-xs) 0;
            font-size: var(--font-height-xxl);
        }

        & > p {
            margin: 0;
            color: var(--view-header-description-color);
            font-size: var(--font-height-md);            

            &:empty {
                display: none;
            }
        }

        & > div.divider {
            padding-top: var(--spacing-xl);
        }
    }

    & > div.page__navigation {
        grid-area: navigation;
        border-right: var(--border-width) solid var(--border-color);
        display: flex;
        flex-direction: column;
        overflow: auto;

        padding-top: 1rem;
        background: var(--page-header-background);
        border-right: none;
        box-shadow: var(--box-shadow-vertical-sm);
        z-index: 250;		

        font-size: 1rem;
        font-weight: 600;

        &:empty {
            display: none;
        }

        & > div {
            &:first-child {
                flex: 1 1 auto;
            }
            &:last-child {
                flex: 0 1 auto;
            }
        }

        button {
            display: flex;
            align-items: center;
			border-radius: 0;			
            text-decoration: none;
			color: var(--title-color);
			fill: var(--title-color); 
            border: 1px solid transparent;
            gap: var(--spacing-md);
            box-shadow: none;

            padding: 0 1rem;
            justify-content: flex-start; 
            max-height: 3rem;
            height: 3rem;    
            font-size: var(--font-height-md);
            font-weight: 600;
            background: transparent;

            &:hover {
                background: var(--page-nav-text-hover-background);
                color: var(--page-nav-text-active-color);				
            }

            &:active {
                background: var(--page-nav-text-active-background);
                color: var(--page-nav-text-active-color);
            }

            &.active {
                background: var(--page-nav-text-active-background);            
                color: var(--page-nav-text-active-color);
                fill: var(--page-nav-text-active-color);

                & > svg.toggle {
                    & .toggle--active {
                        display: inherit;
                    }
                
                    & .toggle--inactive {
                        display: none;
                    }
                }
            }

            & > svg {
                height: 1.2rem;
                width: 1.2rem;           
            }

            & > span {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    & > div.page__content {
        overflow: auto;
        padding: 0 var(--spacing-xl) var(--spacing-xl);

        input,
        select {
            font-size: var(--font-height-md);
        }

        div:not(.input-group) > input,
        .input-group,
        select {
            transition: all .2s;
    
            &:hover {
                @include button-raised();
            }
    
            &:focus {
                @include button-pushed();
    
            }        
        }

        & > div[data-ebview] {
            overflow: auto;
            padding: var(--spacing-xl) 0;

            & div.header {
                & > h3 {
                    margin: var(--spacing-xs) 0;
                }
            
                & > p {
                    margin: 0;
                    color: var(--view-subheader-description-color);
            
                    &:empty {
                        display: none;
                    }
                }
            
                & > div.divider {
                    padding-top: var(--spacing-xl);
                }
            }
        }
    }

    & > div.header {
        padding-bottom: 0;
    }

    .header {
        padding: 1rem;
        background: var(--page-content-header-background);
		margin-bottom: 0.5rem;
		border-radius: var(--radius-md);
        .divider {
            visibility: hidden;
            display: none;
        }

        & ~ .form-control {
            padding-left: 2rem;
        }
        
        &  ~ div {
            .form-control {
                padding-left: 2rem;
            }
        }        
    }

    & > div.content {
        display: grid;
        grid-template-rows: auto auto 1fr;
        grid-template-columns: auto 1fr;
        grid-template-areas:
            "navigation header"
            "navigation header"
            "navigation content";
        overflow: auto;
        padding-bottom: var(--spacing-2xl);

        & > div.navigation {
            grid-area: navigation;
            border-right: var(--border-width) solid var(--border-color);

            display: flex;
            flex-direction: column;
            gap: var(--spacing-xs);
            padding: var(--spacing-xs) 0;
            overflow: auto;

            font-size: 1rem;
            font-weight: 600;

            &:empty {
                display: none;
            }

            & > div {
                &:first-child {
                    flex: 1 1 auto;
                }
                &:last-child {
                    flex: 0 1 auto;
                }
            }

            button {
                display: flex;
                align-items: center;
                border: var(--border-width) solid var(--body-background);
                text-decoration: none;
                color: var(--nav-link-color);
                fill: var(--nav-link-color);
                border: 1px solid transparent;
                padding: var(--spacing-md);
                gap: var(--spacing-md);
                justify-content: start;
                box-shadow: none;

                &.active {
                    color: var(--nav-link-active-color);
                    fill: var(--nav-link-active-color);

                    & > svg.toggle {
                        & .toggle--active {
                            display: inherit;
                        }
                    
                        & .toggle--inactive {
                            display: none;
                        }
                    }
                }
        
                &:hover {
                    color: var(--nav-link-hover-color);
                    fill: var(--nav-link-hover-color);
                }

                & > svg {
                    height: 1.2rem;
                    width: 1.2rem;
                }

                & > span {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        & > div.header {
            grid-area: header;
            padding: var(--spacing-2xl);
            padding-right: 0;
            padding-bottom: 0;
        
            & > h2.title {
                margin: 0 0 .25rem;
            }
    
            & > div.subtitle {
                color: var(--color-gray-3);
            }

            & > div.divider {
                margin-top: var(--spacing-2xl);
            }
        }

        & > div.content {
            grid-area: content;
            overflow: auto;
            padding: var(--spacing-2xl);
            gap: var(--spacing-xl);

            & div.header {
                padding: var(--spacing-2xl) 0;

                & > h3 {
                    margin: 0 0 .25rem;
                }
            }
        }
    }
}