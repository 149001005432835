
#loadingSpinner.spinner {
	background-color: var(--loading-background) !important;
	opacity: 80%;
	
	div {
		width: 7.5rem !important;
		height: 7.5rem !important;
		border: .5rem solid var(--loading-wheel);
		border-top: .5rem solid var(--loading-wheel-colour); // The color of the rotating segment
		border-radius: 50%;
		animation: spin 1s linear infinite;
		
		span {
			visibility: hidden !important;
		}
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
  