input {
    align-self: stretch;
    background: var(--input-background);
    color: var(--input-color);
    fill: var(--input-color);
    border: var(--border-width) solid var(--input-border);
    border-radius: var(--radius-md);
    
    &:invalid {
        background: var(--input-invalid-background);
        color: var(--input-invalid-color);
        fill: var(--input-invalid-color);
        border-color: var(--input-invalid-border);

        &:focus-within {
            outline-color: var(--input-invalid-border);
        }
    }
}

div.tox {
    border: var(--border-width) solid var(--input-border);
    border-radius: var(--radius-md);

    &:invalid {
        border-color: var(--input-invalid-border);
    }
}

div.input-group {
    background: var(--input-background);
    color: var(--input-color);
    fill: var(--input-color);
    display: inline-flex;
    border: var(--border-width) solid var(--input-border);
    border-radius: var(--radius-md);
    gap: var(--spacing-sm);
    box-shadow: 0 1px 2px 0 rgba(16,24,40,.05);
    flex: 1 1 auto;

    &:has(:first-child:invalid) {
        border: var(--border-width) solid var(--input-invalid-border);
    }

    & > :first-child {
        flex: 1 1 auto;
        border: none;
        border-top-left-radius: var(--radius-md);
        border-bottom-left-radius:  var(--radius-md);
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;

        &:invalid {
            background: var(--input-invalid-background);
            color: var(--input-invalid-color);
            fill: var(--input-invalid-color);
            border: none; //var(--border-width) solid var(--input-invalid-border);
        }
    }

    & > :not(:first-child):not(:last-child) {
        margin-top: 0;
        margin-left: var(--border-width);
        margin-right: 0;
        margin-bottom: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }

    & > :last-child:not(:first-child) {
        margin-top: 0;
        margin-left: var(--border-width);
        margin-right: 0;
        margin-bottom: 0;
        border: none;
        border-top-left-radius: 0;
        border-bottom-left-radius:  0;
        border-bottom-right-radius: var(--radius-md);
        border-top-right-radius: var(--radius-md);
        box-shadow: none;
    }
}

div.input {
    display: flex;

    &:focus-within {
        outline: 1px solid;
    }

    & > svg {
        flex: 0 1 auto;
    }

    & > input {
        flex: 1 1 auto;

        &:focus {
            outline: none;
        }
    }
}

div.control {
    display: flex;
    gap: var(--spacing-md);
    margin-bottom: var(--spacing-lg);

    &.vertical {
        flex-direction: column;
    }

    & > :first-child {
        display: flex;
        width: 20rem;
        flex: 0 0 auto;

        & > :first-child {
            flex: 1 1 auto;
        }

        & > :last-child:not(:first-child) {
            border: none;
            height: max-content;
            box-shadow: none;
            flex: 0 0 auto;
        }
    }

    & > :last-child {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        & > div:last-of-type:not(:first-child) {
            font-style: italic;
            font-size: 0.8rem;
        }
    }
}

input.search {
    background-image: url('/assets/images/search.svg');
    background-position-y: center;
    background-position-x: 0.5rem;
    background-repeat: no-repeat;
    padding-left: 2rem;
}

input[type=checkbox] {
	/* Add if not using autoprefixer */
	-webkit-appearance: none;
	/* Remove most all native input styles */
	-moz-appearance: none;
		 appearance: none;
	/* For iOS < 15 */
	background-color: var(--checkbox-background);
	/* Not removed via appearance */
	margin: 0;
	font: inherit;
	color: currentColor;
	width: 1.15rem;
	height: 1.15rem;
	border: 0.15rem solid var(--checkbox-border);
	border-radius: 0.15em;
	transform: translateY(-0.075em);
	display: grid;
	place-content: center;
  }
  
  input[type=checkbox]::before {
	content: "";
	width: 0.65em;
	height: 0.65em;
	-webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
			clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
	transform: scale(0);
	transform-origin: bottom left;
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em var(--form-control-color);
	/* Windows High Contrast Mode */
	background-color: var(--checkbox-tick);
  }
  
  input[type=checkbox]:checked::before {
	transform: scale(1);
  }
  
  input[type=checkbox]:focus {
	outline: max(.2rem, 0.15rem) solid var(--checkbox-border);
	outline-offset: max(.2rem, 0.15rem);
  }
  
  input[type=checkbox]:disabled {
	--form-control-color: var(--form-control-disabled);
	color: var(--form-control-disabled);
	cursor: not-allowed;
  }

fieldset {
    border: none;
}