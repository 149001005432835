div.page {
    display: grid;
    grid-template-rows: auto max-content 1fr;
    grid-template-columns: 1fr max-content;
    grid-template-areas:
        "banner banner"
        "header controls"
        "content content";
    overflow: auto;

    & > div.page__banner {
        grid-area: banner;
        height: auto;
        max-height: 10rem;
        overflow-y: hidden;
		display: none !important;
		visibility: hidden;

        & > img {
            width: 100%;
            height: auto;
        }

        @media screen and (-webkit-min-device-pixel-ratio: 1.1) {
            display: none;
        }

        @media screen and (max-height: 900px) {
            display: none;
        }

        @media screen and (min-height: 901px) {
            display: inherit;
        }
    }

    & > div.page__header {
        grid-area: header;
        display: grid;
        grid-template-rows: repeat(3, max-content);
        grid-template-columns: 1fr max-content;
        grid-template-areas:
            "title controls"
            "subtitle controls"
            "divider divider";
        padding: var(--spacing-xl) var(--spacing-xl) 0;
        
        // box-shadow: var(--box-shadow-vertical-sm);
        border-bottom: 0.1rem solid var(--page-header-border);
        background: var(--page-header-background);
        // background-size: cover;
        // background-position: center;
        z-index: 900;
        min-height: 6rem;

        > h1,p {
            color: var(--title-color);
            fill: var(--title-color); 
        }

        & > h1 {
            grid-area: title;
            margin: var(--spacing-xs) 0;
            font-size: var(--font-height-xxxl);            
        }

        & > p {
            grid-area: subtitle;
            margin: 0;
            font-size: var(--font-height-sm);

            &:empty {
                display: none;
            }
        }
        
        & > div.page__controls {
            grid-area: controls;
            gap: 0.5rem;
            display: inline-flex;
            height: max-content;

			button {
				margin-top: .75rem;
			}
        }

        & > div.divider {
            grid-area: divider;
            padding-top: var(--spacing-xl);
        }
    }

    & > div.page__content {
        grid-area: content;
        display: grid;
        overflow: auto;
        padding: var(--spacing-xl);
        background: var(--page-content-background);
        color: var(--page-content-color);
        fill: var(--page-content-color);
        grid-auto-rows: max-content;

        font-size: var(--font-height-sm);
        
        & > h2.title {
            margin: 0 0 .25rem;
        }

        & > div.subtitle {
            color: var(--color-gray-3);
        }

        & > div.divider {
            margin-top: var(--spacing-2xl);
        }

        .table__content {
            margin-top: var(--spacing-2xl);
        }        
    }
}