  
/* Otherwise, use `::-webkit-scrollbar-*` pseudo-elements */
@supports selector(::-webkit-scrollbar) {
    *::-webkit-scrollbar {
        width: 10px; /* Scrollbar width */
        height: 10px; /* Horizontal scrollbar height */
    }

    *::-webkit-scrollbar-track {
        background: var(--scrollbar-track-background); /* Track background */
        border-radius: 10px; /* Optional: Rounded corners */
    }

    *::-webkit-scrollbar-thumb {
        background: var(--scrollbar-thumb-background); /* Thumb color */
        border-radius: 10px; /* Optional: Rounded corners */
        border: 1px solid var(--scrollbar-thumb-border); /* Adds padding around the thumb */
    }

    *::-webkit-scrollbar-thumb:hover {
        background: var(--scrollbar-thumb-hover); /* Thumb color on hover */
        border: .5px solid var(--scrollbar-thumb-hover-border); /* Adds padding around the thumb */
    }
}
  