div.page.page--wizard {
    grid-template-rows: auto max-content 1fr max-content;
    grid-template-columns: max-content 1fr;
    grid-template-areas:
        "banner banner"
        "header header"
        "content content"
        "wizardcontrols wizardcontrols";

    & > div.page__content {
        overflow: auto;

        & > div[data-ebstep] {
            overflow: auto;

            & div.header {
                & > h2 {
                    margin: var(--spacing-xs) 0;
                }
            
                & > p {
                    margin: 0;
                    color: var(--color-gray-3);
            
                    &:empty {
                        display: none;
                    }
                }
            
                & > div.divider {
                    padding-top: var(--spacing-xl);
                }
            }
        }
    }

    & > div.header {
        padding-bottom: 0;
    }

    & > div.content {
        display: grid;
        grid-template-rows: auto auto 1fr;
        grid-template-columns: auto 1fr;
        grid-template-areas:
            "header"
            "header"
            "content";
        overflow: auto;
        padding-bottom: var(--spacing-2xl);

        & > div.header {
            grid-area: header;
            padding: var(--spacing-2xl);
            padding-right: 0;
            padding-bottom: 0;
        
            & > h2.title {
                margin: 0 0 .25rem;
            }
    
            & > div.subtitle {
                color: var(--color-gray-3);
            }

            & > div.divider {
                margin-top: var(--spacing-2xl);
            }
        }

        & > div.content {
            grid-area: content;
            overflow: auto;
            padding: var(--spacing-2xl);
            gap: var(--spacing-xl);

            & div.header {
                padding: var(--spacing-2xl) 0;

                & > h3 {
                    margin: 0 0 .25rem;
                }
            }
        }
    }

    & > div.wizard__controls {
        grid-area: wizardcontrols;
        display: flex;
        justify-content: end;
        padding: var(--spacing-md);
        gap: var(--spacing-md);
    }
}