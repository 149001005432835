div.form-control {
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 20rem 1fr;
    grid-template-areas:
        "label control";
    column-gap: var(--spacing-md);
    padding: var(--spacing-xl) 0;

	input {
		max-width: 50%;
	}
	// Override width if inside .input_group
	.input-group input {
		max-width: 100%;
	}
	.input-group {
		max-width: calc(50% + (0.875rem * 2));
	}
	// get max width to fields. Ends up being slightly different for select for some reason
	div:not(.input-group) > select {
		max-width: 50%;
        box-sizing: inherit;
	}
	textarea {
		max-width: 50%;
	}	
	// don't apply to select's within cell divs
	div[role="cell"] > select {
		max-width: 100%;
	}

    label {
        font-size: var(--font-height-md);
        line-height: 1.75rem;            
    }

    &.form-control--vertical {
        grid-template-rows: max-content max-content;
        grid-template-columns: 1fr;
        grid-template-areas:
        "label"
        "control";
        row-gap: var(--spacing-md);
        padding: var(--spacing-lg) 0;
    }

    & > div:first-of-type {
        grid-area: label;
        display: grid;
        grid-template-rows: repeat(2, max-content);
        grid-template-columns: 1fr max-content;
        grid-template-areas:
            "label lock"
            "help lock";
        column-gap: var(--spacing-md);
        row-gap: var(--spacing-xs);
        
        & > label {
            grid-area: label;
        }

        & > button {
            grid-area: lock;
            height: max-content;
            width: max-content;

            border: 0;
            box-shadow: none;
        }

        & > span {
            grid-area: help;
            font-size: var(--font-height-sm);
        }
    }

    & > label {
        grid-area: label;
    }

    & > div:last-of-type {
        grid-area: control;
        display: flex;
        flex-direction: column;
        height: fit-content;
    }

    & > input,
    & > select {
        grid-area: control;
    }

    &:has(input:invalid, select:invalid, textarea:invalid, div.tox:invalid) {
        & > div:first-of-type > label {
            color: var(--input-invalid-border);
            fill: var(--input-invalid-border);
        }
    }
}
