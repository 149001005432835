body > #toastContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 99999;
    gap: 1rem;

    & > div {
        background: var(--toast-info-background);
        border: var(--toast-info-border);
        color: var(--toast-info-color);
        fill: var(--toast-info-color);
		font-size: var(--font-height-lg);
		box-shadow: var(--button-shadow-active-light); 
        display: flex;
        align-items: center;
        justify-content: end;
        white-space: nowrap;
        padding: 0.5rem 1rem;
        border-radius: var(--border-radius);

        &.info {
            background: var(--toast-info-background);
            border: var(--toast-info-border);
            color: var(--toast-info-color);
            fill: var(--toast-info-color);
        }
        &.success {
            background: var(--toast-success-background);
            border: var(--toast-success-border);
            color: var(--toast-success-color);
            fill: var(--toast-success-color);
        }
        &.error {
            background: var(--toast-error-background);
            border: var(--toast-error-border);
            color: var(--toast-error-color);
            fill: var(--toast-error-color);
        }
    }
}