button:not([role="switch"]) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 0.875rem;
    // padding: 1rem;
    gap: var(--spacing-xs);

    background: var(--button-secondary-background);
    color: var(--button-secondary-color);
    fill: var(--button-secondary-color);
    border: var(--border-width) solid var(--button-secondary-border);
    border-radius: var(--radius-md);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: var(--font-height-lg);
    font-weight: 600;
    transition: all .2s;

    & > svg {
        height: fill-available;
        width: auto;  
    }

    & > span {
        pointer-events: none;
    }

    &:hover:not(:disabled) {
        background: var(--button-secondary-hover-background);
        color: var(--button-secondary-hover-color);
        fill: var(--button-secondary-hover-color);
        border: var(--border-width) solid var(--button-secondary-hover-border);

        & > svg.toggle {
            & .toggle--active {
                display: inherit;
            }
        
            & .toggle--inactive {
                display: none;
            }
        }
    }

    &:active:not(:disabled) {

        background: var(--button-secondary-active-background);
        fill: var(--button-secondary-active-color);
        color: var(--button-secondary-active-color);   
        border: var(--border-width) solid var(--button-secondary-active-border);

        > svg.toggle {
            .toggle--active {
                display: inherit;
            }
            
            .toggle--inactive {
                display: none;
            }                
        }        
    } 

    &:disabled {
        background: var(--button-secondary-disabled-background);
        color: var(--button-secondary-disabled-color);
        fill: var(--button-secondary-disabled-color);
        border: var(--border-width) solid var(--button-secondary-disabled-border);
    }

    &.primary {
        background-color: var(--button-primary-background);
        color: var(--button-primary-color);
        fill: var(--button-primary-color);
        border: var(--border-width) solid var(--button-primary-border);

        &:hover {
            background: var(--button-primary-hover-background);
            color: var(--button-primary-hover-color);
            fill: var(--button-primary-hover-color);
            border: var(--border-width) solid var(--button-primary-hover-border);
        }

        &:active {
            background: var(--button-primary-active-background);
            fill: var(--button-primary-active-color);
            color: var(--button-primary-active-color);   
            border: var(--border-width) solid var(--button-primary-active-border);
        }        

        &:disabled {
            background: var(--button-primary-disabled-background);
            color: var(--button-primary-disabled-color);
            fill: var(--button-primary-disabled-color);
            border: var(--border-width) solid var(--button-primary-disabled-border);
        }
    }

    &.danger {
        background-color: var(--button-danger-background);
        color: var(--button-danger-color);
        fill: var(--button-danger-color);
        border: var(--border-width) solid var(--button-danger-border);

        &:hover {
            background: var(--button-danger-hover-background);
            color: var(--button-danger-hover-color);
            fill: var(--button-danger-hover-color);
            border: var(--border-width) solid var(--button-danger-hover-border);
        }

        &:active {
            background: var(--button-danger-active-background);
            fill: var(--button-danger-active-color);
            color: var(--button-danger-active-color);   
            border: var(--border-width) solid var(--button-danger-active-border);
        }   

        &:disabled {
            background: var(--button-danger-disabled-background);
            color: var(--button-danger-disabled-color);
            fill: var(--button-danger-disabled-color);
            border: var(--border-width) solid var(--button-danger-disabled-border);
        }
    }
}

button[role="switch"] {
    display: inline-flex;
    margin: 2px;
    padding: var(--spacing-xs);
    border: var(--border-width) solid transparent;
    border-radius: var(--radius-md);
    width: max-content;
    background: transparent;
    color: var(--button-switch-off-color);
    gap: var(--spacing-md);

    & svg {
        display: inline-block;
        width: 3.6rem;
        height: 2.0rem;
        forced-color-adjust: auto;

        & rect {
            display: block;
            stroke-width: 2;
            stroke: currentColor;
            fill: currentColor;
            fill-opacity: 1;

            &.container {
                color: var(--button-switch-off-background);
            }

            &.off {
                color: var(--button-switch-off-bubble);
                -webkit-filter: drop-shadow(2px 0px rgba(16, 24, 40, 0.10));
                filter: drop-shadow(2px 0px rgba(16, 24, 40, 0.10));
            }
            &.on {
                color: var(--button-switch-on-bubble);
                -webkit-filter: drop-shadow(-2px 0px rgba(255, 255, 255, 0.1));
                filter: drop-shadow(-2px 0px rgba(255, 255, 255, 0.1));
            }
        }
    }

    &[aria-checked="false"] {
        & svg rect.on {
            display: none;
        }

        & span.on {
            display: none;
        }
    }

    &[aria-checked="true"] {
        & svg rect.container {
            color: var(--button-switch-on-background);
        }

        & svg rect.off {
            display: none;
        }

        & span.off {
            display: none;
        }
    }
}
