
.eb-select {
    position: relative;
    overflow-y: hidden;
}
.eb-select-dropdown-container {
    position: relative;
    z-index: 1000;
    height: 0px!important;
    top: 0!important;
    left: auto;
    right: auto;
    bottom: auto;

    .eb-select-dropdown-menu {
        min-height: 2rem;
        max-height: 24rem;
        overflow-y: scroll;
        background-color: var(--body-background);
        border: var(--border-width) solid var(--border-color);
        border-radius: var(--border-radius);
        &.lg {
            min-height: 24rem;
            > .eb-select-dropdown-child {
                padding: 1.6rem;
                height: 16rem;
            }
        }

        &.md {
            min-height: 8rem;
            > .eb-select-dropdown-child {
                padding: .8rem;
                height: 8rem;
            }
        }

        &.sm {
            min-height: 2rem;
            > .eb-select-dropdown-child {
                padding: .2rem;
                height: 2rem;
            }
        }
        & > .eb-select-dropdown-child {
            background-color: var(--button-secondary-background);
            color: var(--button-secondary-color);
            padding: .2rem;
            margin-bottom: .5rem;
            display: flex;
            flex-direction: row;
            height: 2rem;
            align-items: flex-start;
            min-width: 0;
            border-bottom: 1px solid var(--button-secondary-border);
            cursor: pointer;

            &:hover {
                background-color: var(--button-secondary-hover-background);
                color: var(--button-secondary-hover-color);
                border-bottom: 1px solid var(--button-secondary-hover-border);
            }

            &.disabled {
                background-color: var(--button-secondary-disabled-background);
                color: var(--button-secondary-disabled-color);
                border-bottom: 1px solid var(--button-secondary-disabled-border);
                cursor: not-allowed;
            }
    
            &.selected {
                background-color: var(--button-secondary-hover-background);
                color: var(--button-secondary-hover-color);
                //border-bottom: 1px solid var(--button-secondary-hover-border);
            }
    
            & > * {
                margin: auto;
                align-content: center;
                object-fit: contain;
                flex: 1;
                height: 100%;
                max-width: 100%;
                width: auto;
            }

            /*& > img {
                height: 4rem;
                max-height: 4rem;
                width: auto;

                &:hover {
                    position: fixed;
                    left: calc(50% - 5rem);
                    top: 50%;
                    height: 10rem;
                    max-height: 10rem;

                }
            }*/
        }
    }
}