div.dragSort {  
    &.table--fill {
    }

    & > div.table__controls {     
        & > :first-child {
        }

        &:not(:has(:not([style*="display: none"]))), /* Fix for hidden objects */
        &:not(:has(:not(.hidden))) /* Fix for hidden objects */,
        &:empty {
        }
    }

    div.table__content {
    }

    div.table__header { 

        & > div {

            &:last-of-type {
            }

            & > svg {
            }

            &[aria-sort="ascending"] > svg > path.toggle--down {
            }

            &[aria-sort="descending"] > svg > path.toggle--up {
            }
        }
    }

    div.table__rows {

        a {        
        }

        & > div {
            &:nth-of-type(even) {
            }
            & > .orderAbove {
                display: none;
            }
            & > .orderBelow {
                display: none;
            }
        }
    }

	div.table__insert {
		select {
		}
	}
    
    div.table__rows.sorting > div {
        &[draggable=true]{
            & > .orderAbove {
                display: block;
            }
            & > .orderBelow {
                display: block;
            }
        }
    }

    div.table__rows > div[role="row"],
    div.table__insert {
        
        &.dragging {
            background: orange;
        }

        & > .orderAbove {
            position: absolute;
            z-index: 12;
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
        }
        & > .orderBelow {
            position: absolute;
            z-index: 12;
            top: 50%;
            left: 0;
            width: 100%;
            height: 50%;
        }

        & > div[role="cell"] {

            &:last-of-type {
                & > button {
                }
            }
        }
    }
}