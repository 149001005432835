eb-dropdown-button {
    display: none;
    position: absolute;
    background: var(--input-background);
    border: var(--border-width) solid var(--input-border);
    // border-radius: var(--border-radius);
    color: var(--input-color);
    fill: var(--input-color);
    padding: var(--border-width);
    margin: 0;
    
    & button:not([role="switch"]) {
		// background: none;
		box-shadow: none;
		border-radius: 0;
        //display: inline-flex;
        //align-items: center;
        //padding: 0.4em;
        //column-gap: 0.2em;
        text-transform: capitalize!important;
        border: none!important;
        justify-content: start!important;
    }
}