@import '../abstracts/mixins';

div.login-page > div.page__content {
    background: var(--body-background);
    
    label {
        color: var(--login-content-color);
    }

    & > form {
        margin: auto auto;

        border-radius: var(--radius-md);    
        margin-top: 10rem;
        background: var(--login-content-background);
        height: fit-content;
        box-shadow: var(--box-shadow-horizontal-sm);
        font-size: var(--font-height-lg);
        // padding: var(--spacing-xl);
        padding: 2.5rem;
        width: 30rem;
    
        max-width: 30rem;
        //min-width: 5rem;

        margin-bottom: 1.6rem;
        // remove the weird absolute positioned magnifying glass
        &:before {
            display: none;
        }

        div.control {
            margin-bottom: 2rem;
        }

        input {
            max-width: 100%;
            font-size: var(--font-height-lg);
            transition: all .2s;			
            &:hover {
                @include button-raised();
            }
    
            &:focus {
                @include button-pushed();
    
            }   			
        }

        & input:before {
            content: "";
            position: absolute;
            left: 1rem;
            top: 0;
            bottom: 0;
            width: 2rem;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill-rule='evenodd'%3E%3Cpath d='M16.036 18.455l2.404-2.405 5.586 5.587-2.404 2.404zM8.5 2C12.1 2 15 4.9 15 8.5S12.1 15 8.5 15 2 12.1 2 8.5 4.9 2 8.5 2zm0-2C3.8 0 0 3.8 0 8.5S3.8 17 8.5 17 17 13.2 17 8.5 13.2 0 8.5 0zM15 16a1 1 0 1 1 2 0 1 1 0 1 1-2 0'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
        }
    }
}

body[data-theme="light"] div.login-page > div.page__content {
    a.signin-with-microsoft {
        svg.light { display: inherit; }
        svg.dark { display: none; }
    }
}
body[data-theme="dark"] div.login-page > div.page__content {
    a.signin-with-microsoft {
        svg.light { display: none; }
        svg.dark { display: inherit; }
    }
}