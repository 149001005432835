a {
    color: var(--link-color);

    &:visited {
        color: var(--link-visited-color);
    }

    &:hover {
        color: var(--link-hover-color);

        & > svg.toggle {
            & .toggle--active {
                display: inherit;
            }
        
            & .toggle--inactive {
                display: none;
            }
        }
    }

    &:active {
        color: var(--link-active-color);
    }

    &[target="_blank"]:not(.no-link-arrow):after {
        content: "↗";
        display: inline-block;
        padding-left: 0.2rem;
        text-decoration: none;
    }
}