.grid__body {
        overflow: auto;
        height: 100%;
        width: 100%;

        & div.grid__widgetmenu {
            position: fixed;
            left: calc(100% - 28.5rem);
            top: 14.5rem;
            height: 40rem;
            width: 25rem;
            background-color: var(--modal-background);
            border: 1px solid var(--modal-border);
            color: var(--modal-color);
            z-index: 4;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            & > button.grid_widgetmenu_close{
                color: var(--modal-header-color);
                fill: var(--modal-header-color);
                position: absolute;
                top: 0;
                right: 0;
                height: 32px;
                width: 32px;
                background: transparent;
                border: 0px;
            }

            & > div.grid__widgetmenuheader {
                height: 5rem;
                color: var(--modal-header-color);
                fill: var(--modal-header-background);
                background-color: var(--modal-header-background);
                border-bottom: 1px solid;
                padding: 0.6em;
                text-transform: capitalize;
                text-align: center;
                 & > p {
                    font-size: large;
                    text-transform: capitalize;
                    text-align: center;
                 }
            }

            & > div.grid__widgetmenubody {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: start;
                overflow-y: auto;
                border: 1px solid var(--colorButtonSecondaryText);
                text-transform: capitalize;
                text-align: center;
                color: var(--modal-color);
                & > div {
                    color: var(--modal-color);
                    height: 4rem;
                    padding: 0.3em;
                    text-transform: capitalize;
                    text-align: left;
                    cursor: pointer;
                    border-bottom: 1px var(--colorButtonSecondaryText) solid;
                    & > span {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-size: large;
                        & > svg {
                            height: 2.4rem;
                            width: 2.4rem;
                            margin-right: 0.4em;
                        }
    
                    }
    
                    &:hover {
                        color: var(--modal-header-background);
                        text-decoration: underline;
                    }
                    &[eb-focus]{
                        border: 3px solid green;
                    }
                }
            }

            & > div:last-child {
                height: 5rem;
                background: var(--modal-header-background);
                color: var(--modal-header-color);
                fill: var(--modal-header-background);
                border: 1px solid var(--modal-border);
                padding: 0.6em 0em;
                text-transform: capitalize;
                text-align: center;
                & > button {
                    width: 80%;
                    margin: auto;
                    padding: 0.6em;
                    font-size: large;
                    text-transform: capitalize;
            
                    & > svg {
                        display: none;
                        height: 2.4rem;
                        width: 2.4rem;
                        margin-right: 0.4em;
                    }
                }
            }
        }

        & div.grid {
            min-width: 100%;
            min-height: 100%;
            position: relative;

            & div.grid-shadow{
                opacity: 0.2;
                background-color: var(--body-background);
                z-index: 2;
                filter: invert(1);
            }
            
            & div.grid__contextmenu{
                //box-shadow: 0 3.2px 7.2px 0 white,0 .6px 1.8px 0 white;
                position: fixed;
                z-index: 10;
                background-color: rgba(0, 0, 0, 0);
                border-radius: var(--radius-md);
                    & div.contextmenu__body {
                        
                        height: 100%;
                        min-width: 15rem;
                        & div.contextmenu__content {
                            
                            height: 5rem;
                            width: 100%;

                            & > svg {
                                margin-right: 0.4em;
                                height: 1.5em;
                                width: 1.5em;
                            }
                    
                            & > button {
                                width: 100%;
                                //border: none;
                                margin-left: auto;
                            }
                        }
                    }
            }
        }
        & div.grid-show {
            background-color: var(--body-background);
            background-image: linear-gradient(to right, var(--border-color) 2px, transparent 1px), 
                              linear-gradient(to bottom, var(--border-color) 2px, transparent 1px), 
                              linear-gradient(to left, var(--border-color) 2px, transparent 1px), 
                              linear-gradient(to top, var(--border-color) 2px, transparent 1px);
    
            & .widget {
                cursor: move;
                resize: both;

                &[eb-moving]{
                    z-index: 3;
                    opacity: 0.75;
                }

                & button.widget_deleteBtn {
                    z-index: 3;
                    position: absolute;
                    top: 10px;
                    left: 5px;
                    color: var(--body-color);
                    fill: var(--body-color);
                    display: block;
                }

                & button.widget_settingsBtn {
                    z-index: 3;
                    position: absolute;
                    top: 10px;
                    right: 5px;
                    color: var(--body-color);
                    fill: var(--body-color);
                    display: block;
                }

                & div.widget__content-cover {
                    z-index: 2;
                    background-color: var(--body-background);
                    opacity: 0.5;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    filter: invert(1);
                }
            }
        }

        .widget {
            background-color: var(--body-background);
            // border-color: invert(var(--body-background)); // removed as you can't invert a colour
            border-color: var(--body-background);
            color: var(--body-color);
            fill: var(--body-color);

            box-shadow: var(--box-shadow-horizontal-sm);
            font-size: var(--font-height-md);

            position: absolute;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            border-radius: var(--radius-md);
            z-index: 2;
            overflow-x: hidden;
            width: calc(100% - 2px);
            height: 100%;
        
            & div.widget__header {
                display: inline-flex;
                align-items: center;
                justify-content: left;
                font-weight: 600;
                font-size: larger;
                padding-left: 1rem;
                padding-right: 1rem;
                // border-color: invert(var(--body-background)); // removed as you can't invert a colour
                border-color: var(--body-background);
                //padding-top: 1rem;

                background: var(--widget-header-background);
                background-attachment: fixed;
                color: var(--widget-header-color);
                fill: var(--widget-header-color);                

                & > svg {
                    margin-right: 0.4em;
                    height: 1.5em;
                    width: 1.5em;
                }
        
                & > button {
                    border: none;
                    margin-left: auto;
                }
            }
        
            & div.widget__controls {
                display: inline-flex;
                align-items: center;
                justify-content: left;
                border-bottom: 1px solid var(--colorControlBorder);
                margin-bottom: 0.4em;
                width: 100%;
        
                &:empty {
                    display: none!important;
                }
        
                & > button {
                    background: var(--colorButtonSecondaryBackground);
                    color: var(--colorButtonSecondaryText);
                    fill: var(--colorButtonSecondaryText);
                    border: 1px solid var(--colorButtonSecondaryBackground);
                    padding: 0.6em;
                    margin: 0em 1px;
        
                    &:focus {
                        border: 1px solid var(--colorButtonSecondaryBorderFocus);
                    }
        
                    &:disabled,
                    &:disabled:hover {
                        background: var(--colorButtonSecondaryBackgroundDisabled);
                        color: var(--colorButtonSecondaryTextDisabled);
                        fill: var(--colorButtonSecondaryTextDisabled);
                        text-decoration: none;
                    }
        
                    &:hover {
                        background: var(--colorButtonSecondaryBackground);
                        color: var(--colorButtonSecondaryText);
                        fill: var(--colorButtonSecondaryText);
                        text-decoration: underline;
                    }
        
                    & > svg {
                        margin-right: 0.2em;
                    }
        
                    &.active {
                        border-bottom: .2rem solid var(--colorButtonSecondaryText);
                    }
                }
            }
        
            & div.widget__content-cover {
                display: none;
            }

            & button.widget_deleteBtn {
                display: none;
            }

            & button.widget_settingsBtn {
                display: none;
            }
            
            & div.widget__content {
                padding-left: 1rem;
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                overflow-y: auto;

                background: var(--widget-content-background);    
                padding: 1rem;

                hr {
                    color: var(--widget-header-color);
                    fill: var(--widget-header-color);                    
                }

                p {
                    color: var(--widget-header-color);
                    fill: var(--widget-header-color);                    
                }

                // & .table {
                    // & .table__controls{
                        //display: none;
                    // }
                // }

                & .console-qa-header {

                    label {
                        color: var(--qa-text-color);
                        fill: var(--qa-text-color);
                    }

                    & > div:first-of-type {
                        margin-bottom: 4.5rem;
                    }
                   
                    & > div {
                        line-height: 1.4em;
                        display: flex;
                        flex-direction: row;
                        flex: auto;
                        justify-content: space-between;
                        margin-bottom: .5rem;
                        text-transform: capitalize;
                        font-size: larger;

                        & > button[eb-focus] {
                            background: var(--button-tertiary-background);
                            color: var(--button-tertiary-color);
                            fill: var(--button-tertiary-color);
                            border: 1px solid var(--button-tertiary-border);

                            &:hover {
                                background: var(--button-tertiary-hover-background);
                                color: var(--button-tertiary-hover-color);
                                fill: var(--button-tertiary-hover-color);
                                border: 1px solid var(--button-tertiary-hover-border);
                            }
							&:active {
								background: var(--button-tertiary-active-background);
								fill: var(--button-tertiary-active-color);
								color: var(--button-tertiary-active-color);   
								border: var(--border-width) solid var(--button-tertiary-active-border);
							}							
                        }

                        & > button {
                            font-size: medium;
                            width: 45%;
                            & > span {
                                text-align: center;
                                vertical-align: middle;
                                margin: auto;
                            }

							
                        }
                    }

                }

                & .console-qa-body {
					
                    margin-bottom: 5rem;
                    & > div[eb-view="pending"] {
                        & > div {
                            width: 100%;
                            display: grid;
                            grid-template-columns: 1fr max-content;
                            grid-template-rows: max-content;
                            column-gap: 1rem;

                            & > div:first-of-type {
                                color: black;
                                overflow-wrap: break-word;
                                background-color: lightgreen;
                                border-radius: .5rem;
                                padding: 1rem;
                                //min-width: 80%;
                                & > div {
                                    margin-bottom: 1rem;
                                }
                            }
                            /*& > div:not(:first-of-type):not(:last-of-type) {
                                min-width: 5%;
                            }*/
                            & > div:last-of-type {
                                display: grid;
                                grid-template-columns: max-content max-content;
                                grid-template-rows: max-content max-content;
                                grid-template-areas: "reply forward" "replyall dismiss";
                                //color: black;
                                //align-content: center;
                                //vertical-align: middle;
                                //margin-left: auto;
                                //margin-top: auto;
                                margin-bottom: auto;
                                //min-width: 10%;
                                /*& > button {
                                    //padding: 1rem;
                                    //width: 100%;
                                    //margin-top: auto;
                                    //margin-bottom: auto;
                                    & > span {
                                        margin: auto;
                                        text-align: center;
                                        vertical-align: middle;
                                    }
                                }*/

                                & > button {
                                    &[eb-action="reply"] { grid-area: reply; }
                                    &[eb-action="replyall"] { grid-area: replyall; }
                                    &[eb-action="forward"] { grid-area: forward; }
                                    &[eb-action="dismiss"] { grid-area: dismiss; }
                                }
                            }
                        }
                        & > div:not(:last-child){
                            margin-bottom: 2.5rem;
                        }
                    }
                    & > div[eb-view="log"] {
                        & > div {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            & > div  {
                                position: relative;
                                & > button.chat-edit-button{
                                    position: absolute;
                                    top: 5px;
                                    right: 5px;
                                }
                            }
                            & > div:first-of-type {
                                color: black;
                                overflow-wrap: break-word;
                                background-color: lightgreen;
                                border-radius: .5rem;
                                padding: 1rem;
                                width: 45%;
                                & > div {
                                    margin-bottom: 1rem;
                                }
                            }
                            & > div:first-of-type:empty {
                                display: none;
                            }
                            & > div:last-of-type:not(:empty) {
                                color: black;
                                overflow-wrap: break-word;
                                background-color: lightblue;
                                width: 45%;
                                border-radius: .5rem;
                                padding: 1rem;
                                margin-left: auto;
                                & > div {
                                    margin-bottom: 1rem;
                                }
                            }
                            & > div:last-of-type:empty {
                                display: none;
                            }
                        }
                        & > div:not(:last-child){
                            margin-bottom: 2.5rem;
                        }
                    }
                }

                & .console-qa-footer {
                    margin-top: auto;
                    & > .console-qa-footer-reply-to {
                        width: 100%;
                        overflow-wrap: break-word;
                        text-wrap: pretty;
                        //font-style: italic;
                        background: var(--colorButtonSecondaryBackground);
                        color: var(--colorButtonSecondaryText);
                        fill: var(--colorButtonSecondaryText);
                    }
                    & > .console-qa-footer-buttons{
                        display: flex;
                        justify-content: end;
                        /*& > button {
                            margin-left: auto;
                            margin-right: 0;
                        }*/
                    }
                }

                & .console-widget-flex-col {
                    display: flex;
                    flex-direction: column;
                }

                & .console-widget-flex {
                    background: var(--colorButtonSecondaryBackground);
                    color: var(--colorButtonSecondaryText);
                    fill: var(--colorButtonSecondaryText);
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                    /*& > button {
                        background: var(--button-secondary-background);
                        color: var(--button-secondary-color);
                        fill: var(--button-secondary-color);
                        border: 1px solid var(--button-secondary-border);
                        padding: 0.6em;
                        margin: 0em 1px;
            
                        &:focus {
                            border: 1px solid var(--button-secondary-border);
                        }
            
                        &:disabled,
                        &:disabled:hover {
                            background: var(--button-secondary-disabled-background);
                            color: var(--button-secondary-disabled-color);
                            fill: var(--button-secondary-disabled-color);
                            border: 1px solid var(--button-secondary-disabled-border);
                            text-decoration: none;
                        }
            
                        &:hover {
                            background: var(--button-secondary-hover-background);
                            color: var(--button-secondary-hover-color);
                            fill: var(--button-secondary-hover-color);
                            border: 1px solid var(--button-secondary-hover-border);
                            //text-decoration: underline;
                        }
            
                        & > svg {
                            margin-right: 0.2em;
                        }
            
                        &.active {
                            background: var(--button-primary-background);
                            color: var(--button-primary-color);
                            fill: var(--button-primary-color);
                            border: 1px solid var(--button-primary-border);

                            &:hover {
                                background: var(--button-primary-hover-background);
                                color: var(--button-primary-hover-color);
                                fill: var(--button-primary-hover-color);
                                border: 1px solid var(--button-primary-hover-border);
                            }

                            //border-bottom: 2px solid var(--colorButtonSecondaryText);
                        }
                    }*/
                }

                & .widget-console-info {
                    background: var(--colorButtonSecondaryBackground);
                    color: var(--colorButtonSecondaryText);
                    fill: var(--colorButtonSecondaryText);
                    width: 100%;
                    height: 100%;
                    & > .eb-progress-bar-container {
                        height: 2.4rem;
                        width: calc(100%);
                        display: flex;
                        align-items: center;
                        border-radius: var(--radius-sm);                 
                        border-width: 1px 0px;
                        & > .eb-progress-bar {
                            height: 100%;
                            width: 0%;
                            background-color: rgba(0, 0, 0, 0.6);
                            border-radius: var(--radius-sm);
                        }
                    }

                    & > .eb-event-info-widget {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        color: var(--qa-text-color);
                        fill: var(--qa-text-color);
                    }
                }

                & .widget-event-list {
                    width: 100%;
                    
                    & > div{
                        text-align: center;
                        background-color: lightseagreen;
                        font-size: larger;
                    }
                }
        
                &.widget__content--with-sidebar {
                    flex-direction: row;
                    column-gap: 1em;
        
                    & div.widget__content {
                        overflow-y: unset;
                    }
                }
            }
        }
}

#console-grid {
	.widget__content p {
		color: var(--qa-text-color);
		fill: var(--qa-text-color);		
	}
	button:not([role="switch"]):not(:disabled):not([aria-disabled="false"]):not(.tox-tbtn)  {
		background: var(--button-tertiary-background);
		color: var(--button-tertiary-color);
		fill: var(--button-tertiary-color);
		border: 1px solid var(--button-tertiary-border);

		&:hover {
			background: var(--button-tertiary-hover-background);
			color: var(--button-tertiary-hover-color);
			fill: var(--button-tertiary-hover-color);
			border: 1px solid var(--button-tertiary-hover-border);
		}
		&:active {
			background: var(--button-tertiary-active-background);
			fill: var(--button-tertiary-active-color);
			color: var(--button-tertiary-active-color);   
			border: var(--border-width) solid var(--button-tertiary-active-border);
		}			
	}
}