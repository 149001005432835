@mixin button-raised() {
  transform: translateY(-3px);
  box-shadow: var(--button-shadow-hover-light);    
}

@mixin button-pushed() {
  transform: translateY(-1px);
  box-shadow: var(--box-shadow-horizontal-sm);
}

@mixin button-style(
    $color: var(--button-primary-color), $background: var(--button-primary-background), $border: var(--button-primary-border),
    $hover-color: var(--button-primary-hover-color), $hover-background: var(--button-primary-hover-background), $hover-border: var(--button-primary-hover-border),
    $active-color: var(--button-primary-active-color), $active-background: var(--button-primary-active-background), $active-border: var(--button-primary-active-border),    
    ) {
  font-size: var(--font-height-lg);
  fill: $color;
  color: $color;
  align-items: center;
  border: var(--border-width) solid $border;
  border-radius: var(--radius-md);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  background-color: $background;
  display: flex;
  font-weight: 600;
  gap: var(--spacing-xs);
  justify-content: center;
  padding: 0.625rem 0.875rem;
  text-decoration: none;

  transition: all .2s;
  &:hover {
      fill: $hover-color;
      color: $hover-color;
      background: $hover-background;
      border: var(--border-width) solid $hover-border;

      > svg.toggle {
          .toggle--active {
              display: inherit;
          }
          
          .toggle--inactive {
              display: none;
          }                
      }

  }

  &:active {
      fill: $active-color;
      color: $active-color;   
      background: $active-background;
      border: var(--border-width) solid $active-border;

      > svg.toggle {
          .toggle--active {
              display: inherit;
          }
          
          .toggle--inactive {
              display: none;
          }                
      }        
  }         
}

@mixin for-media-size($size) {
    @if $size == phone-only {
      @media (max-width: 599px) { @content; }
    } @else if $size == tablet-portrait-up {
      @media (min-width: 600px) { @content; }
    } @else if $size == tablet-landscape-up {
      @media (min-width: 900px) { @content; }
    } @else if $size == desktop-up {
      @media (min-width: 1200px) { @content; }
    } @else if $size == big-desktop-up {
      @media (min-width: 1800px) { @content; }
    }
}

@mixin mixin_media($breakpoints) {
    $conditions: ();
    @each $breakpoint in $breakpoints {
        // If the key exists in the map
        $conditions: append(
            $conditions,
            #{inspect(map-get($media_queries, $breakpoint))},
            comma
        );
    }
  
    @media #{$conditions} {
        @content;
    }
}
  
@mixin mixin_container($container, $breakpoint) {
    @container #{$container} #{map-get($container-queries, $breakpoint)} {
        @content;
    }
}