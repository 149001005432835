#app {
    display: grid;
    height: inherit;
    grid-template-rows: 4.85rem auto 1fr;
    grid-template-columns: max-content 1fr;
    grid-template-areas:
        "title-bar title-bar"
        "notifications notifications"
        "navigation content";

    div.app__title-bar {
        grid-area: title-bar;

        fill: var(--title-bar-color);
        color: var(--title-bar-color);        
        border-bottom: 0.1rem solid var(--title-bar-border);
        background: var(--title-bar-background);
        height: var(--title-bar-height);
        padding: 0;
        display: flex;
        justify-content: space-between;
        z-index: 2000;        

        & > div {
            display: flex;
            align-items: center;
            justify-content: left;
            height: 70%;
            transform: translate(15%, 25%);            
        }

        div:last-of-type {
            padding-right: 2rem;
        }

        button {
            background: transparent;
            border: none;

            padding: .75rem;
            border-radius: 4px;
            transition: color .35s ease, transform .2s, box-shadow .2s; 
    
            fill: var(--button-title-color);
            color: var(--button-title-color);

            & > svg {
                height: 2rem;
                width: 2rem;
            }
    
            &:hover {
                fill: var(--button-title-hover-color);
                color: var(--button-title-hover-color);
                background: var(--button-title-hover-background);         
            }   
    
            &:active {
                fill: var(--button-title-active-color);
                color: var(--button-title-active-color);
                background: var(--button-title-active-background);                  
            }        

        }
    }

    & > div.navigation {
        grid-area: navigation;

        padding: 0;
        gap: 0;
        overflow-y: auto;
        overflow-x: hidden;
        background: var(--sidenav-background);
        box-shadow: var(--box-shadow-vertical-sm);
        z-index: 1000;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;

        font-size: 0.75rem;
        font-weight: 600;

        div {
            font-size: var(--font-height-sm);
        }

        a {
            font-size: var(--font-height-xm);
            font-weight: var(--fontWeightRegular);
            padding: 0;
            justify-content: center;
            width: 6.8rem;
            height: 5rem;
            text-align: center;
            transition: color .35s ease, transform .35s ease; 
            position: relative;

            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;
            fill: var(--sidenav-link-color);
            color: var(--sidenav-link-color);

            &:visited {
                fill: var(--sidenav-link-visited-color);
                color: var(--sidenav-link-visited-color);
            }

            &:hover {
                fill: var(--sidenav-link-hover-color);
                color: var(--sidenav-link-hover-color);
                background: var(--sidenav-link-hover-background);
            }

            &:active {
                fill: var(--sidenav-link-active-color);
                color: var(--sidenav-link-active-color);                
                background: var(--sidenav-link-active-background); 
            }

            &.active {
                color: var(--sidenav-link-active-color);
                fill: var(--sidenav-link-active-color);

                // draw the selection line
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: .4rem;
                    width: 2px; /* Line thickness */
                    height: 100%;
                    background-color: var(--sidenav-link-active-color); /* Line color */
                }

                & > svg.toggle {
                    & .toggle--active {
                        display: inherit;
                    }
                
                    & .toggle--inactive {
                        display: none;
                    }
                }
            }

            & > svg {
                height: 2em;
                width: 2em;
            }

            & > span {
                display: block;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        hr {
            color: var(--sidenav-hr-color);
        }        
    }

    & > div.app__content {
        grid-area: content;
        display: grid;
        overflow: auto;
    }
}

#user-menu {
    color: var(--body-color);
    fill: var(--body-color);
	font-size: var(--font-height-lg);
	border-bottom-left-radius: var(--radius-md);
	background: var(--page-navigation-background);
	color: var(--nav-link-color);
	fill: var(--nav-link-color);
	box-shadow: var(--box-shadow-vertical-left-sm);
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 0px;
    height: 15rem;
    width: 30rem;
    overflow-y: auto;
    padding: 1em;
	z-index: 1000;
	a {
		&:hover {
			background: var(--page-nav-text-hover-background);
			color: var(--page-nav-text-active-color);
		}
	}	
}