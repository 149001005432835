html {
    font-family: Inter, sans-serif;
    font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
    --title-bar-height: 4.8rem;
    --fontSizeBase100: 10px;
    --fontWeightRegular: 400;

    --box-shadow-horizontal-sm: 0 2px 5px rgba(0, 0, 0, .4);
    --box-shadow-vertical-sm: 2px 0 5px rgba(0, 0, 0, .4);
    --box-shadow-vertical-left-sm: -2px 0 5px rgba(0, 0, 0, .4);	
    --button-shadow-hover-light: 0 2.5px 10px rgba(0,0,0,.2);
    --button-shadow-active-light: 0 5px 5px rgba(0,0,0,.4);   
    --button-shadow-hover-dark: 0 5px 20px rgba(0,0,0,.6);
    --button-shadow-active-dark: 0 5px 5px rgba(0,0,0,.6);
	
    --color-blue-light: #0AABF5;
    --color-blue: #05567C;
    --color-blue-dark: #032D40;
    --color-lime-light: #D7E7AA;
    --color-lime: #A8CB43;
    --color-lime-dark: #74A31E;
    --color-orange-light: #FCD4A3;
    --color-orange: #F9A138;
    --color-orange-dark: #DB8E31;
    --color-success: #74A31E;
    --color-warning: #DB8E31;
    --color-danger: #e1180b;
	

    --color-white: #FFFFFF; // 100
    --color-gray-light-1: #fafafa; // 98 
    --color-gray-light-2: #F5F5F5; // 96
    --color-gray-light-3: #ebebeb; // 92
    --color-gray-light-4: #e0e0e0; // 88
    --color-gray-light-5: #d9d9d9; // 85

    --color-gray-1: #A6A6A6; // 65
    --color-gray-2: #616161; // 38
    --color-gray-3: #595959; // 35
	--color-gray-4: #4C4C4C; // 30
	--color-gray-5: #3F3F3F; // 25
	
    --color-gray-dark-1: #333333; // 20
	--color-gray-dark-2: #272727; // 15
	--color-gray-dark-3: #1f1f1f; // 12
	--color-gray-dark-4: #1a1a1a; // 10
	--color-gray-dark-5: #141414; // 8
    --color-black: #0d0d0d; // 5
	
	--color-branding: #05567C;
	--color-branding-dark: #032D40;	

    --font-height-xm: 1rem;
    --font-height-sm: 1.2rem;
    --font-height-md: 1.4rem;
    --font-height-lg: 1.6rem;
    --font-height-xl: 1.8rem;
    --font-height-xxl: 2.2rem;
    --font-height-xxxl: 2.6rem;    

    --spacing-xs: 0.25rem;
    --spacing-sm: 0.25rem;
    --spacing-md: 0.5rem;
    --spacing-lg: 1rem;
    --spacing-xl: 1.5rem;
    --spacing-xxl: 1.5rem;
    --spacing-2xl: 1.25rem;
    --spacing-3xl: 1.5rem;

    --radius-md: 1rem;
    --radius-sm: .5rem;

    --border-radius: 0.5em;
    --border-width: 1px;
    --border-color: var(--color-gray-1);

    --body-background: var(--color-white);
    --body-color: var(--color-gray-2);

	--loading-background: var(--color-white);
	--loading-wheel: var(--color-gray-light-2);	
	--loading-wheel-colour: var(--color-branding);	

    --nav-link-color: var(--color-gray-3);
    --nav-link-active-color: var(--color-gray-3);
    --nav-link-hover-color: var(--color-gray-3);

    --link-color: var(--color-gray-3);
    --link-active-color: var(--color-gray-3);
    --link-visited-color: var(--color-gray-3);
    --link-hover-color: var(--color-gray-3);
    --link-disabled-color: var(--color-gray-3);
    
    --input-background: var(--color-white);
    --input-color: var(--color-gray-dark-1);
    --input-border: var(--color-gray-light-4);
    --input-hover-background: var(--color-white);
    --input-hover-color: var(--color-gray-dark-1);
    --input-hover-border: var(--color-gray-1);
    --input-disabled-background: var(--color-white);
    --input-disabled-color: var(--color-gray-dark-1);
    --input-disabled-border: var(--color-gray-1);
    --input-invalid-background: var(--color-white);
    --input-invalid-color: var(--color-gray-dark-1);
    --input-invalid-border: var(--color-danger);

    --button-primary-background: var(--color-gray-light-3);
    --button-primary-border: var(--color-gray-light-3);
    --button-primary-color: var(--nav-link-color);
    --button-primary-hover-background: var(--color-white);
    --button-primary-hover-border: var(--color-white);
    --button-primary-hover-color: var(--color-branding);
    --button-primary-active-background: var(--color-gray-light-4);
    --button-primary-active-border: var(--color-gray-light-4);    
    --button-primary-active-color: var(--color-branding);
    --button-primary-disabled-background: var(--color-gray-1);
    --button-primary-disabled-border: var(--color-gray-1);
    --button-primary-disabled-color: var(--color-gray-dark-1);

    --button-secondary-background: var(--color-gray-light-3);
    --button-secondary-border: var(--color-gray-light-3);
    --button-secondary-color: var(--nav-link-color);
    --button-secondary-hover-background: var(--color-white);
    --button-secondary-hover-border: var(--color-white);
    --button-secondary-hover-color: var(--color-branding);
    --button-secondary-active-background: var(--color-gray-light-4);
    --button-secondary-active-border: var(--color-gray-light-4);     
    --button-secondary-active-color: var(--colorNeutralForeground3BrandSelecte);
    --button-secondary-disabled-background: var(--color-gray-1);
    --button-secondary-disabled-border: var(--color-gray-1);
    --button-secondary-disabled-color: var(--color-gray-dark-1);

    --button-tertiary-background: var(--color-branding);
    --button-tertiary-border: var(--color-branding);
    --button-tertiary-color: var(--color-white);
    --button-tertiary-hover-background: var(--color-white);
    --button-tertiary-hover-border: var(--color-white);
    --button-tertiary-hover-color: var(--color-branding);
    --button-tertiary-active-background: var(--color-gray-light-4);
    --button-tertiary-active-border: var(--color-gray-light-4);     
    --button-tertiary-active-color: var(--colorNeutralForeground3BrandSelecte);
    --button-tertiary-disabled-background: var(--color-gray-1);
    --button-tertiary-disabled-border: var(--color-gray-1);
    --button-tertiary-disabled-color: var(--color-gray-dark-1);

    --button-danger-background: var(--color-danger);
    --button-danger-border: var(--color-black);
    --button-danger-color: var(--color-white);
    --button-danger-hover-background: var(--color-white);
    --button-danger-hover-border: var(--color-danger);
    --button-danger-hover-color: var(--color-danger);
    --button-danger-active-background: var(--color-white);
    --button-danger-active-border: var(--color-danger);    
    --button-danger-active-color: var(--color-danger);
    --button-danger-disabled-background: var(--color-danger-light);
    --button-danger-disabled-border: var(--color-black);
    --button-danger-disabled-color: var(--color-black);

    --button-title-background: var(--color-gray-light-3);
    --button-title-border: var(--color-gray-light-3);
    --button-title-color: var(--color-gray-2);
    --button-title-hover-background: var(--color-gray-light-1);
    --button-title-hover-border: var(--color-white);
    --button-title-hover-color: var(--color-branding);
    --button-title-active-background: var(--color-gray-light-4);
    --button-title-active-border: var(--color-gray-light-4);    
    --button-title-active-color: var(--color-branding);

    --button-switch-off-background: var(--color-light);
    --button-switch-off-bubble: var(--color-white);
    --button-switch-off-color: var(--color-gray-light-5);
    --button-switch-on-background: var(--color-branding);
    --button-switch-on-bubble: var(--color-white);
    --button-switch-on-color: var(--color-white);

    --modal-backdrop: var(--color-gray-3);
    --modal-background: var(--color-white);
    --modal-color: var(--color-black);
    --modal-border: var(--color-gray-1);
    --modal-header-background: var(--color-branding);
    --modal-header-color: var(--color-white);

    --toast-info-background: var(--color-blue-light);
    --toast-info-border: 1px solid var(--color-blue-light);
    --toast-info-color: var(--color-white);
    --toast-success-background: var(--color-lime-dark);
    --toast-success-border: 1px solid var(--color-lime-dark);
    --toast-success-color: var(--color-white);
    --toast-error-background: var(--color-danger);
    --toast-error-border: 1px solid var(--color-danger);
    --toast-error-color: var(--color-white);

    --title-color: var(--color-white);    
    --title-bar-color: var(--color-gray-2);
    --title-bar-background: var(--color-gray-light-3);
    --title-bar-border: var(--color-gray-light-5);

    --login-content-background: var(--color-branding);
    --login-content-color: var(--color-white);

    --table-header-color: var(--color-white);
    --table-header-background: var(--color-gray-2);
    --table-header-svg: var(--color-white);

    --table-rows-background: var(--color-gray-light-3);
    --table-rows-background-alt: var(--color-gray-light-5);    
	--table-rows-insert-background: var(--color-gray-light-2);
	--table-rows-insert-color: var(--color-gray-2);

    --sidenav-background: var(--color-gray-light-3);
    --sidenav-link-color: var(--color-gray-2);
    --sidenav-link-visited-color: var(--color-gray-2);
    --sidenav-link-hover-color: var(--color-branding);
    --sidenav-link-hover-background: var(--color-gray-light-1); 
    --sidenav-link-active-color: var(--color-branding);
    --sidenav-link-active-background: var(--color-gray-light-4);
    --sidenav-hr-color: var(--color-gray-light-5);

    --scrollbar-track-background: var(--color-gray-light-2);
    --scrollbar-thumb-border: var(--color-gray-1);
    --scrollbar-thumb-background: var(--color-gray-1);
    --scrollbar-thumb-hover: var(--color-gray-3);
    --scrollbar-thumb-hover-border: var(--color-gray-3);

    --page-header-background: var(--color-branding);
    --page-header-border: var(--color-branding-dark);

    --page-content-header-background: var(--color-gray-light-2);
    --page-content-background: var(--color-white);
    --page-navigation-background: var(--color-gray-light-2);
    
    --page-nav-text-hover-background: var(--color-gray-light-5);
    --page-nav-text-active-background: var(--color-white);
    --page-nav-text-active-color: var(--color-branding);

    --view-header-border: var(--color-gray-light-3);

    --widget-header-background: var(--color-branding);
    --widget-header-color: var(--color-white);
    --widget-content-background: var(--color-gray-light-1);

    --qa-text-color: var(--color-gray-2);

	--checkbox-tick: var(--color-branding);
	--checkbox-background: var(--color-white);
	--checkbox-border: var(--color-branding);

    --tinymce-header-background: var(--color-branding);
    --tinymce-header-text: var(--color-white);
    --tinymce-body-background: var(--color-white);
}
