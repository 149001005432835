html {
	font-size: 62.5%;  	
}

html,
body {
    background-color: var(--body-background);
    color: var(--body-color);
    fill: var(--body-color);
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    font-family: Inter, sans-serif; 
    font-style: normal;
    font-weight: 400;
}

body {
	font-size: var(--font-height-md); 	
}

input, textarea, button {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
}

svg {
    pointer-events: none;

    &.toggle {
        & .toggle--active {
            display: none;
        }
    
        & .toggle--inactive {
            display: inherit;
        }
    }
}

label {
    align-self: stretch;

    color: var(--body-color);
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}

input, select, textarea {
    //display: flex;
    padding: 0.625rem 0.875rem;
    // padding: 1rem;
    //align-items: center;
    gap: var(--spacing-md);
    //align-self: stretch;
    min-width: 1rem;

    color: var(--input-color);
    border-radius: var(--radius-md);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

textarea {
    resize: none;
}

.hide,
.hidden {
    display: none!important;
}

div.section-header {
    display: block;
    padding: var(--spacing-xl);
    border-bottom: 1px solid purple;
    margin-bottom: var(--spacing-xl);

    h1,h2,h3,h4,h5,h6 {
        margin: 0;
    }
}

.m0 { margin: 0!important; }
.m1 { margin: var(--spacing-xs)!important; }
.m2 { margin: var(--spacing-sm)!important; }
.m3 { margin: var(--spacing-md)!important; }
.m4 { margin: var(--spacing-lg)!important; }
.m5 { margin: var(--spacing-xl)!important; }
.m6 { margin: var(--spacing-2xl)!important; }
.mt0 { margin-top: 0!important; }
.mt1 { margin-top: var(--spacing-xs)!important; }
.mt2 { margin-top: var(--spacing-sm)!important; }
.mt3 { margin-top: var(--spacing-md)!important; }
.mt4 { margin-top: var(--spacing-lg)!important; }
.mt5 { margin-top: var(--spacing-xl)!important; }
.mt6 { margin-top: var(--spacing-2xl)!important; }
.mb0 { margin-bottom: 0!important; }
.mb1 { margin-bottom: var(--spacing-xs)!important; }
.mb2 { margin-bottom: var(--spacing-sm)!important; }
.mb3 { margin-bottom: var(--spacing-md)!important; }
.mb4 { margin-bottom: var(--spacing-lg)!important; }
.mb5 { margin-bottom: var(--spacing-xl)!important; }
.mb6 { margin-bottom: var(--spacing-2xl)!important; }
.ml0 { margin-left: 0!important; }
.ml1 { margin-left: var(--spacing-xs)!important; }
.ml2 { margin-left: var(--spacing-sm)!important; }
.ml3 { margin-left: var(--spacing-md)!important; }
.ml4 { margin-left: var(--spacing-lg)!important; }
.ml5 { margin-left: var(--spacing-xl)!important; }
.ml6 { margin-left: var(--spacing-2xl)!important; }
.mr0 { margin-right: 0!important; }
.mr1 { margin-right: var(--spacing-xs)!important; }
.mr2 { margin-right: var(--spacing-sm)!important; }
.mr3 { margin-right: var(--spacing-md)!important; }
.mr4 { margin-right: var(--spacing-lg)!important; }
.mr5 { margin-right: var(--spacing-xl)!important; }
.mr6 { margin-right: var(--spacing-2xl)!important; }

.p0 { padding: 0!important; }
.p1 { padding: var(--spacing-xs)!important; }
.p2 { padding: var(--spacing-sm)!important; }
.p3 { padding: var(--spacing-md)!important; }
.p4 { padding: var(--spacing-lg)!important; }
.p5 { padding: var(--spacing-xl)!important; }
.p6 { padding: var(--spacing-2xl)!important; }
.pt0 { padding-top: 0!important; }
.pt1 { padding-top: var(--spacing-xs)!important; }
.pt2 { padding-top: var(--spacing-sm)!important; }
.pt3 { padding-top: var(--spacing-md)!important; }
.pt4 { padding-top: var(--spacing-lg)!important; }
.pt5 { padding-top: var(--spacing-xl)!important; }
.pt6 { padding-top: var(--spacing-2xl)!important; }
.pb0 { padding-bottom: 0!important; }
.pb1 { padding-bottom: var(--spacing-xs)!important; }
.pb2 { padding-bottom: var(--spacing-sm)!important; }
.pb3 { padding-bottom: var(--spacing-md)!important; }
.pb4 { padding-bottom: var(--spacing-lg)!important; }
.pb5 { padding-bottom: var(--spacing-xl)!important; }
.pb6 { padding-bottom: var(--spacing-2xl)!important; }
.pl0 { padding-left: 0!important; }
.pl1 { padding-left: var(--spacing-xs)!important; }
.pl2 { padding-left: var(--spacing-sm)!important; }
.pl3 { padding-left: var(--spacing-md)!important; }
.pl4 { padding-left: var(--spacing-lg)!important; }
.pl5 { padding-left: var(--spacing-xl)!important; }
.pl6 { padding-left: var(--spacing-2xl)!important; }
.pr0 { padding-right: 0!important; }
.pr1 { padding-right: var(--spacing-xs)!important; }
.pr2 { padding-right: var(--spacing-sm)!important; }
.pr3 { padding-right: var(--spacing-md)!important; }
.pr4 { padding-right: var(--spacing-lg)!important; }
.pr5 { padding-right: var(--spacing-xl)!important; }
.pr6 { padding-right: var(--spacing-2xl)!important; }
