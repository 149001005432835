body[data-theme="dark"] {
	--color-danger: #f54e44;
	
    --border-color: var(--color-gray-dark-1);

    --body-background: var(--color-black);
    --body-color: var(--color-gray-1);

	--loading-background: var(--color-black);
	--loading-wheel: var(--color-gray-dark-3);	 
	--loading-wheel-colour: var(--color-gray-light-1); 

    --nav-link-color: var(--color-gray-1);
    --nav-link-active-color: var(--color-gray-1);
    --nav-link-hover-color: var(--color-gray-1);

    --link-color: var(--color-gray-1);
    --link-active-color: var(--color-gray-1);
    --link-visited-color: var(--color-gray-1);
    --link-hover-color: var(--color-gray-1);
    --link-disabled-color: var(--color-gray-1);

    --input-background: var(--color-black);
    --input-color: var(--color-gray-1);
    --input-border: var(--color-gray-1);
    --input-hover-background: var(--color-black);
    --input-hover-color: var(--color-gray-1);
    --input-hover-border: var(--color-gray-1);
    --input-disabled-background: var(--color-black);
    --input-disabled-color: var(--color-gray-1);
    --input-disabled-border: var(--color-gray-1);
    --input-invalid-background: var(--color-black);
    --input-invalid-color: var(--color-gray-1);
    --input-invalid-border: var(--color-danger);

    --button-primary-background: var(--color-black);
    --button-primary-border: var(--color-gray-1);
    --button-primary-color: var(--color-gray-1);
    --button-primary-hover-background: var(--color-gray-1);
    --button-primary-hover-border: var(--color-gray-1);
    --button-primary-hover-color: var(--color-white);
    --button-primary-active-background: var(--color-gray-light-4);
    --button-primary-active-border: var(--color-gray-light-4);    
    --button-primary-active-color: var(--color-black);
    --button-primary-disabled-background: var(--color-gray-2);
    --button-primary-disabled-border: var(--color-gray-1);
    --button-primary-disabled-color: var(--color-gray-1);

    --button-secondary-background: var(--color-black);
    --button-secondary-border: var(--color-gray-1);
    --button-secondary-color: var(--color-gray-1);
    --button-secondary-hover-background: var(--color-gray-1);
    --button-secondary-hover-border: var(--color-gray-1);
    --button-secondary-hover-color: var(--color-white);
    --button-secondary-active-background: var(--color-gray-light-4);
    --button-secondary-active-border: var(--color-gray-light-4); 	
    --button-secondary-active-color: var(--color-black);
    --button-secondary-disabled-background: var(--color-gray-2);
    --button-secondary-disabled-border: var(--color-gray-1);
    --button-secondary-disabled-color: var(--color-gray-1);
    
    --button-tertiary-background: var(--color-black);
    --button-tertiary-border: var(--color-gray-1);
    --button-tertiary-color: var(--color-gray-1);
    --button-tertiary-hover-background: var(--color-gray-1);
    --button-tertiary-hover-border: var(--color-gray-1);
    --button-tertiary-hover-color: var(--color-white);
    --button-tertiary-active-background: var(--color-gray-light-4);
    --button-tertiary-active-border: var(--color-gray-light-4); 	
    --button-tertiary-active-color: var(--color-black);
    --button-tertiary-disabled-background: var(--color-gray-2);
    --button-tertiary-disabled-border: var(--color-gray-1);
    --button-tertiary-disabled-color: var(--color-gray-1);

    --button-title-background: var(--color-gray-light-3);
    --button-title-border: var(--color-gray-light-3);
    --button-title-color: var(--color-gray-1);
    --button-title-hover-background: var(--color-gray-light-1);
    --button-title-hover-border: var(--color-white);
    --button-title-hover-color: var(--color-branding);
    --button-title-active-background: var(--color-gray-dark-3);
    --button-title-active-border: var(--color-gray-dark-3);    
    --button-title-active-color: var(--color-branding);

    --button-switch-off-background: var(--color-gray-dark-5);
    --button-switch-off-bubble: var(--color-gray-3);
    --button-switch-off-color: var(--color-gray-1);
    --button-switch-on-background: var(--color-gray-light-4);
    --button-switch-on-bubble: var(--color-gray-3);
    --button-switch-on-color: var(--color-gray-1);

    --modal-backdrop: var(--color-black);
    --modal-background: var(--color-gray-dark-3);
    --modal-color: var(--color-gray-1);
    --modal-border: var(--color-gray-1);
    --modal-header-background: var(--color-gray-dark-4);
    --modal-header-color: var(--color-gray-1);

    --title-color: var(--color-gray-1);    
    --title-bar-color: var(--color-gray-1);
    --title-bar-background: var(--color-gray-dark-5);
    --title-bar-border: var(--color-black);

    --login-content-background: var(--color-gray-dark-3);
    --login-content-color: var(--color-white);

    --table-header-color: var(--color-white);
    --table-header-background: var(--color-gray-dark-4);
    --table-header-svg: var(--color-white);

    --table-rows-background: var(--color-gray-dark-2);
    --table-rows-background-alt: var(--color-gray-dark-1);
	--table-rows-insert-background: var(--color-gray-4);
	
    --sidenav-background: var(--color-gray-dark-5);
    --sidenav-link-color: var(--color-gray-1);
	--sidenav-link-visited-color: var(--color-gray-1);
    --sidenav-link-hover-color: var(--color-branding);
    --sidenav-link-hover-background: var(--color-gray-dark-4); 
    --sidenav-link-active-color: var(--color-branding);
    --sidenav-link-active-background: var(--color-gray-dark-2);
    --sidenav-hr-color: var(--color-gray-light-5);

    --scrollbar-track-background: var(--color-black);
    --scrollbar-thumb-border: var(--color-gray-1);
    --scrollbar-thumb-background: var(--color-gray-dark-4);
    --scrollbar-thumb-hover: var(--color-gray-dark-1);
    --scrollbar-thumb-hover-border: var(--color-gray-1);

    --page-header-background: var(--color-gray-dark-4);
    --page-header-border: var(--color-black);

    --page-content-header-background: var(--color-gray-dark-5);
    --page-content-background: var(--color-gray-dark-3);
    --page-navigation-background: var(--color-gray-dark-2);
	
    --page-nav-text-hover-background: var(--color-gray-dark-3);
    --page-nav-text-active-background: var(--color-gray-dark-2);
    --page-nav-text-active-color: var(--color-branding);

    --view-header-border: var(--color-black);
	--view-header-background: var(--color-gray-dark-5);
	--view-header-description-color: var(--color-gray-1);
	--view-subheader-description-color: var(--color-gray-1);

    --widget-header-background: var(--color-gray-dark-5);
    --widget-header-color: var(--color-white);
    --widget-content-background: var(--color-gray-dark-5);

    --qa-text-color: var(--color-gray-1);

	--checkbox-tick: var(--color-white);
	--checkbox-background: var(--color-black);
	--checkbox-border: var(--color-white);
    
    --tinymce-header-background: var(--color-black);
    --tinymce-header-text: var(--color-gray-1);
    --tinymce-body-background: var(--color-gray-1);
}