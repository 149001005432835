
$modal-breakpoint-sm: 500px;
$modal-breakpoint-md: 800px;
$modal-breakpoint-lg: 1000px;

body > div.modal {
    display: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    z-index: 2400; //auto;

    &.modal--sm > div.dialog {
        max-width: 25%;
        max-height: 90%;
    }

    &.modal--md > div.dialog {
        max-width: 50%;
        max-height: 90%;
    }

    &.modal--lg > div.dialog {
        max-width: 90%;
        max-height: 90%;
    }

    &.modal--fullscreen > div.dialog {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        margin: 0;
        border: none;
    }

    & > div.backdrop {
        background-color: var(--modal-backdrop);
        color: var(--colorText);
        fill: var(--colorText);
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.5;
    }

    & > div.dialog {
        display: grid;
        grid-template-rows: auto max-content 1fr max-content;
        grid-template-columns: 1fr max-content;
        grid-template-areas:
            "banner banner"
            "header close"
            "content content"
            "controls controls";
        overflow: auto;

        background-color: var(--modal-background);
        color: var(--modal-color);
        border: var(--border-width) solid var(--modal-border);
        border-radius: var(--radius-md);
        max-width: 50rem;
        margin: 4.88rem;
        margin-right: auto;
        margin-left: auto;
        opacity: 1;
        position: relative;
        width: auto;
            
        & > div.banner {
            grid-area: banner;
            height: auto;
            max-height: 12rem;
            overflow-y: hidden;

            & > img {
                width: 100%;
                height: auto;
            }
        }

        & > div.header {
            display: grid;
            grid-area: header;
            padding: var(--spacing-lg);
            background: var(--modal-header-background);
            color: var(--modal-header-color);
            fill: var(--modal-header-color);
            
            & > h1.title {
                margin: 0;
                margin-bottom: 0.25rem;
            }

            & > div.subtitle {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        & > div.controls {
            grid-area: controls;
            gap: 0.5rem;
            padding: var(--spacing-lg);
            border-top: 1px solid var(--border-color);
            display: inline-flex;
            height: max-content;
            justify-content: end;
        }

        & > div.close {
            grid-area: close;
            padding: var(--spacing-lg);
            background: var(--modal-header-background);
            color: var(--modal-header-color);
            fill: var(--modal-header-color);

            & > button {
                border: none;
                background: transparent;
                color: var(--modal-header-color);
                fill: var(--modal-header-color);
                box-shadow: none;

                &:focus {
                    outline: 1px solid purple;
                }

                &:hover {
                    color: var(--modal-header-color);
                    fill: var(--modal-header-color);
                }
            }
        }

        & > div.content {
            grid-area: content;
            padding: var(--spacing-xl);
            overflow: auto;
            
            & div.header {
                & > h2 {
                    margin: var(--spacing-xs) 0;
                }
            
                & > p {
                    margin: 0;
                    color: var(--color-gray-3);
            
                    &:empty {
                        display: none;
                    }
                }
            
                & > div.divider {
                    padding-top: var(--spacing-xl);
                }
            }
        }

    }
}
